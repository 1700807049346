.container {
    grid-template-columns: 1fr;
    position: relative;
    .container-item {
        display: flex;
        flex: 1;
    }
    width: 100%;
}

.left-arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: rotateY(0) rotate(45deg);
}

.right-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;
    transform: rotateY(0) rotate(-45deg);
}
