.container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto 1fr;
    .container-item {
        display: flex;
        &:first-child {
            grid-column: 1 / span 2;
            grid-row: 1 / span 2;
            justify-content: center;
            align-items: center;
        }
        &:nth-child(2) {
            grid-column: 3;
            grid-row: 1 / span 2;
            align-items: center;
        }
        &:nth-child(3) {
            grid-column: 3;
            grid-row: 3;
        }
        &:nth-child(4) {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 200px;
            overflow: hidden;
        }
        @media (min-width: 768px) {
          &:nth-child(4) {
            height: 350px;
          }
        }
        @media (min-width: 992px) {
          &:nth-child(4) {
            height: 400px;
          }
        }
        @media (min-width: 1200px) {
          &:nth-child(4) {
            height: 500px;
          }
        }
    }
}

.landscape {
    height: 100%;
    width: 100%;
    background-image: url("/assets/images/cow_silhouette1.svg"), url("/assets/images/cows_landscape.svg");
    background-repeat: no-repeat;
    background-blend-mode: normal;
    background-size: 5% 5%, cover;
    background-position: center, center;
    /** Ken Burns effect, @credits to Saijo George
    @ref https://codepen.io/SaijoGeorge/pen/LxeGgY */
    animation: move 40s ease infinite;
    
    -ms-animation: move 40s ease infinite;
    -webkit-animation: move 40s ease infinite;
    -o-animation: move 40s ease infinite;
    -moz-animation: move 40s ease infinite;
    position: absolute;    
}

@keyframes move {
  0% {
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
    transform: scale(1.0);
    -ms-transform: scale(1.0);
    /* IE 9 */
    
    -webkit-transform: scale(1.0);
    /* Safari and Chrome */
    
    -o-transform: scale(1.0);
    /* Opera */
    
    -moz-transform: scale(1.0);
    /* Firefox */
  }
  100% {
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    /* IE 9 */
    
    -webkit-transform: scale(1.2);
    /* Safari and Chrome */
    
    -o-transform: scale(1.2);
    /* Opera */
    
    -moz-transform: scale(1.2);
    /* Firefox */
  }
}


.strikethrough {
    position: relative;
    display: inline;
    &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        right: 0;
        border-top: 2px solid;
        border-color: inherit;
        transform: rotate(8deg);
    }
}

.circular-sb {
  width: 250px;
  border: 5px solid rgb(21, 225, 255);
  padding: 80px 0px;
  margin: 30px auto;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  font-weight: 900;
  font-family: arial;
  position: relative;
  color: rgb(21, 225, 255);
}

.circle1 {
  border: 5px solid rgb(21, 225, 255);
  position: absolute;
  width: 25px;
  padding: 20px;
  border-radius: 50%;
  right: -15px;
  bottom: 23px;
  &:before {
    content: "";
    position: absolute;
    width: 25px;
    padding: 20px;
    border-radius: 50%;
    right: 0px;
    bottom: 0px;
    background: #fff;   
  }
}

.circle2 {
  border: 5px solid rgb(21, 225, 255);
  position: absolute;
  width: 5px;
  padding: 10px 15px;
  border-radius: 50%;
  right: -60px;
  bottom: 5px;
}

/** Credits to Chris Smith for the CSS Picture Frame
    @ref https://codepen.io/chris22smith/pen/PbBwjp
*/

.frame {
    background-color:#ddc;
    border:solid 5vmin #eee;
    border-bottom-color:#fff;
    border-left-color:#eee;
    border-radius:2px;
    border-right-color:#eee;
    border-top-color:#ddd;
    box-shadow:0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
    box-sizing:border-box;
    display:inline-block;
    margin:10vh 10vw;
    //padding:8vmin;
    position:relative;
    text-align:center;
    &:before {
      border-radius:2px;
      bottom:-2vmin;
      box-shadow:0 2px 5px 0 rgba(0,0,0,.25) inset;
      content:"";
      left:-2vmin;
      position:absolute;
      right:-2vmin;
      top:-2vmin;
    }
    &:after {
      border-radius:2px;
      bottom:-2.5vmin;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
      content:"";
      left:-2.5vmin;
      position:absolute;
      right:-2.5vmin;
      top:-2.5vmin;
    }
    img {
        height: 100%;
    }
  }