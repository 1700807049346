@font-face {
  font-family: Gidole;
  src: url("./assets/fonts/GidoleFont/Gidole-Regular.otf");
}

@font-face {
  font-family: Gidolinya;
  src: url("./assets/fonts/GidoleFont/Gidolinya-Regular.ttf");
}

body {
  margin: 0;
  font-family: Gidole, "Oak Light", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

body, html {
  height: 100%;
  width: 100%;
}

h2 {
  font-family: Gidolinya;
  font-size: 2.2rem;
  line-height: 3.2rem;
}

h3 {
  margin-top: 0;
  font-size: 1.5rem;
  line-height: 2.9rem;
}

a {
  text-decoration: none;
  color: rgb(21, 225, 255);
}