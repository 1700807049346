.section-intro {
    background-color: #1D1D1F;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 1;
 
   .logo {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     fill: #1D1D1F;
   }
 
   @media screen and (max-width: 500px) {
       height: auto;
       overflow: auto;
       overflow-x: hidden;
       display: none;      
    }

    &.loaded {
       -webkit-transform: translateY(-100%);
       -ms-transform: translateY(-100%);
       transform: translateY(-100%);
       -webkit-transition: 1s cubic-bezier(.77, 0, .175, 1).2s;
       transition: 1s cubic-bezier(.77, 0, .175, 1).2s;
    }
  }

  .fill {
    -webkit-transition: fill 2.4s cubic-bezier(.77, 0, .175, 1)1.3s;
    -moz-transition: fill 2.4s ease;
    -o-transition: fill 2.4s ease;
    transition: fill 2.4s ease;
    fill: #FFFFFF;
  }

  .fill.cursor {
    display: inherit;
  }

  .cursor {
    display: none;
    position: absolute;
    top: 36%;
    left: 90%;
    margin-left: 2px;
    font-weight: 100;
    font-size: 60px;
    color: #2E3D48;

    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
  }

  @keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-moz-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-webkit-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-ms-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

@-o-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: black;
  }
}