.nav-sticky {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #353535;
    .nav-wrapper {
        display: flex;
        & > * {
            flex: 1;
        }
        a {
            color: rgb(244,248,249);
            text-decoration: none;
            text-transform: capitalize;
            &:hover {
                transform: translate3d(0px, -10px, 0px) scale3d(1, 1, 1);
                transform-style: preserve-3d;
                opacity: 1;
                background-color: rgb(21, 225, 255);
                color: #353535;
                height: 100%;
            }
        }
        @media (min-width: 768px) {
            padding: 0 0;
        }
        @media (min-width: 992px) {
            padding: 0 110px;
        }
        @media (min-width: 1200px) {
            padding: 0 210px;
        }
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}