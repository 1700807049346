/**
    Credits to Alex Moore
    @ref https://codepen.io/MoorLex/embed/pRRzKx?default-tab=result&editable=true&theme-id=0
*/

.cards-group {
    width: 100%;
    height: 100%;
    position: relative;
    .card {
        top: 0px;
        left: 0px;
        opacity: 0;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        transition: 0.5s;
        visibility: hidden;
        position: relative;
        padding: 50px 20px;
        position: absolute;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
  
        &:hover{
          
          .bg{
            opacity: 0.2;
          }
          
          .block{
            box-shadow: 0px 0px 50px #333;
          }
        }
  
        &.active{
          opacity: 1;
          visibility: visible;
        }
  
        .bg{
          top: 0px;
          left: 0px;
          opacity: 1;
          z-index: -1;
          width: 100%;
          height: 100%;
          transition: 0.5s;
          position: absolute;
          transform: scale(1.03);
          background-size: cover;
          background-position: center;
          background-attachment: fixed;
        }
        
        .blur{
          top: 0px;
          left: 0px;
          z-index: -1;
          width: 100%;
          height: 100%;
          transition: 0.5s;
          filter: blur(5px);
          position: absolute;
          transform: scale(1.03);
          background-size: cover;
          background-position: center;
          background-attachment: fixed;
        }
  
        .block{
          width: 100%;
          height: 100%;
          padding: 20px;
          color: #ffffff;
          transition:box-shadow 0.5s;
          max-width: 300px;
          overflow: hidden;
          max-height: 450px;
          border-radius: 10px;
          transform: scale(1.03);
          box-sizing: border-box;
          background-size: cover;
          background-position: center;
          background-attachment: fixed;
          box-shadow: none;
          
          .text{
            width: 100%;
            height: 100%;
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            background-position: center;
            background-attachment: fixed;
          }
          
          h2{
            font-size: 3vw;
            margin-bottom: 25px;
          }
          
          p{
            font-size: 15px;
          }
          
          &:hover{
            box-shadow: 0px 0px 70px #111;
            cursor: pointer;
          }
          
        }
    }
}