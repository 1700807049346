/**
    Credits to Brysen for circular arrow button
    @ref https://codepen.io/brysenackx/pen/XjAAGR
*/
.round {
    position: relative;
    border: 3px solid #fff;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    .round-stroke {
        z-index: 999;
        height: 3px;
        margin:1px;
        width: 30px;
        background: #fff;
        transition: 0.4s ease;
        &:first-child {
            display: block;
            position: absolute;
            transform: rotate(45deg);
            left: 25%;
            bottom: 35%
        }
        &:nth-child(2) {
            display: block;
            position: absolute;
            transform: rotate(-45deg);
            left: 45%;
            bottom: 35%;
        }
        &:nth-child(3) {
            display: block;
            position: absolute;
            transform: rotate(45deg);
            left: 25%;
            bottom: 54%;
        }
        &:nth-child(4) {
            display: block;
            position: absolute;
            transform: rotate(-45deg);
            left: 45%;
            bottom: 54%;
        }
    }
    &:hover .round-stroke {
        &:first-child {
            transform: rotate(-135deg);
        }
        &:nth-child(2) {
            transform: rotate(135deg);
        }
        &:nth-child(3) {
            transform: rotate(225deg);
        }
        &:nth-child(4) {
            transform: rotate(-225deg);
        }
    }
}